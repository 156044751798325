import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const mainnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.SKALE,
    name: "SKALE Europa DeFi Hub",
    rpcUrl: "https://mainnet.skalenodes.com/v1/elated-tan-skat",
    currency: "sFUEL",
    explorerUrl: "https://elated-tan-skat.explorer.mainnet.skalenodes.com",
  },
  cgt: "0xf97a23a0E49e5E0028F8Ce89F7be9a9FD5942Cf6",
  whitelist: "0x709706236F4DcE1F93460c9b1F3eE8Be339d4A3E",
  router: "0x4965A5616F7151f3a7f6376d2574272C7C3b8437",
  multicall: "0x5b10155A62Bb797E546D46EB929C52AC838c72d4",
  blocksPerYear: 31536000,
  farming: [
    {
      masterChef: "0xE511dB450d1360d152D0965E18272861DF94Bb60",
      rewardToken: "CGT",
      rewardPerMonth: 0,
      pools: [],
    },
  ],
};
