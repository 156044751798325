import { Modal } from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import classes from "./GetFuelModal.module.css";

export const GetFuelModal = ({
  balance,
  onCloseButton,
}: {
  balance: string;
  onCloseButton: () => void;
}) => {
  return (
    <Modal title="Get sFUEL" onCloseButtonClick={onCloseButton}>
      <div className={classNames(classes.Inner, "modal-inner")}>
        <p className="p1 semi">
          You&apos;ll need sFUEL (SKALE&apos;s gas token with no market value)
          for transactions on the Europa SKALE chain. You can top up your sFUEL
          balance using the faucet.
        </p>
        <p className="p2 regular color-gray">Balance sFUEL: {balance}</p>
        <a
          href=" https://sfuelstation.com/"
          target="_blank"
          rel="noreferrer"
          className="btn block"
        >
          Go to faucet
        </a>
      </div>
    </Modal>
  );
};
